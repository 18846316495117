import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 2rem;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

export const Image = styled(GatsbyImage)`
  width: auto;
  height: 50vw;

  @media screen and (min-width: 788px) {
    height: 30vw;
  }

  @media screen and (min-width: 1100px) {
    height: 20vw;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 25px;

  @media screen and (min-width: 788px) {
    width: 48%;
  }
`;

export const CardDetail = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid gray;
  border-top: 0px;

  p:nth-child(1) {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.7em;
    font-weight: 500;
  }

  p:nth-child(2) {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  p:nth-child(3) {
    margin-bottom: 20px;
    text-align: justify;
  }
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const Button = styled(Link)`
  margin-top: 25px;
  padding: 0.4em 1em;
  background-color: #013977;
  color: white;
  border: 2px solid;
  border-radius: 20px;
  transition: 0.5s ease-in;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    border-color: #EB2D31;
  }
`;
