import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import {
  Card, Container, Wrapper, Image, CardDetail, ItemLink, Button,
} from '../styles/blog.elements';

interface ImgData extends IGatsbyImageData {
  title: string
}
interface IBlog {
  title: string;
  slug: string;
  publishDate: string;
  tags: string[];
  body: { raw: string }
  image: ImgData
}

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(sort: {fields: publishDate, order: DESC}) {
        nodes {
          title
          slug
          publishDate(formatString: "MMMM DD, YYYY", locale: "es")
          tags
          image {
            gatsbyImageData
            title
          }
          body {
            raw
          }
        }
      }
    }
  `);

  const blogs = data.allContentfulBlog.nodes;

  return (
    <Layout>
      <main>
        <Wrapper>
          <Container>
            {
              blogs.map((blog: IBlog) => {
                const bodyText = documentToPlainTextString(JSON.parse(blog.body.raw));
                let nextSpace = 0;

                for (let i = 1; i < 20; i += 1) {
                  if (bodyText[250 + i] === ' ') {
                    nextSpace = i;
                    break;
                  }
                }

                const resume = `${bodyText.slice(0, 250 + nextSpace)}....`;
                return (
                  <Card key={blog.slug}>
                    <ItemLink to={`/blog/${blog.slug}`}>
                      <Image image={getImage(blog.image) as IGatsbyImageData} alt={blog.image.title} />
                    </ItemLink>
                    <CardDetail>
                      <ItemLink to={`/blog/${blog.slug}`}><p>{blog.title}</p></ItemLink>
                      <p>{`${blog.publishDate} | ${blog.tags?.[0] || ''}`}</p>
                      <p>{resume}</p>
                      <Button to={`/blog/${blog.slug}`}>Ver mas</Button>
                    </CardDetail>
                  </Card>
                );
              })
            }
          </Container>
        </Wrapper>
      </main>
    </Layout>
  );
};

export default BlogPage;

export const Head: HeadFC = () => <Seo title="Blog" />;
